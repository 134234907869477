@content4: content4;
.@{content4}-wrapper {
  min-height: 720px;
  background: #fafafa;
  .@{content4} {
    height: 100%;
    overflow: hidden;
    &-video {
      border-radius: 4px;
      overflow: hidden;
      max-width: 800px;
      margin: auto;
      background: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
      video {
        display: block;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content4}-wrapper {
    min-height: 350px;
    .@{content4} {
      overflow: hidden;
      width: 90%;
      margin: auto;
      &-video {
        top: 15%;
        background: url("https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg") no-repeat center;
        background-size: cover;
      }
    }
  }
}
